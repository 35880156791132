





















































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import FormValidator from "@/components/mixins/FormValidator.ts";

@Component
export default class PublicacionNoticia extends Mixins(FormValidator) {
  private nombreWeb: any = "";
  private dataNoticia: any = "";
  private slide = 1;

  private created() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticia();
    }
  }

  private getInfoNoticia() {
    this.$q.loading.show({
      delay: 100, // ms
    });
    this.$axios
      .get("noticias/detalle?nombre_web=" + this.nombreWeb)
      .then((res: any) => {
        this.dataNoticia = res.data;

        if (this.dataNoticia.foto) {
          this.dataNoticia.foto =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto;
        }

        if (this.dataNoticia.foto2) {
          this.dataNoticia.foto2 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto2;
        }

        if (this.dataNoticia.foto3) {
          this.dataNoticia.foto3 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto3;
        }

        if (this.dataNoticia.foto4) {
          this.dataNoticia.foto4 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto4;
        }

        if (this.dataNoticia.foto5) {
          this.dataNoticia.foto5 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto5;
        }

        if (this.dataNoticia.foto6) {
          this.dataNoticia.foto6 =
            "https://d1rofaen6s22p4.cloudfront.net/" + this.dataNoticia.foto6;
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);
    const hora = fechaInicio.substring(11, 16);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return (
        diaInicio + " de " + mesInicio + " " + anhoInicio + " a las " + hora
      );
    }
  }

  private toLink(link) {
    window.open(link, "_blank");
  }
}
