






import { Vue, Component, Prop } from "vue-property-decorator";
import PublicacionNoticia from "@/components/Noticias/PublicacionNoticia.vue";

@Component({
  components: {
    PublicacionNoticia
  }
})
export default class PublicacionNoticiaView extends Vue {
  private mounted() {}
}
