






import { Vue, Component, Prop } from "vue-property-decorator";
import Noticias from "@/components/Noticias/NoticiasComponent.vue";

@Component({
  components: {
    Noticias
  }
})
export default class NoticiasView extends Vue {
  private mounted() {}
}
